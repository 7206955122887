<template>
  <keep-alive :include="['goodsListIndex']">
    <router-view></router-view>
  </keep-alive> 
  <!-- <router-view></router-view> -->
</template>

<script>
export default {

}
</script>

<style>

</style>